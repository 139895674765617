export default {
  barcodeNotFound: 'Produktsträckkod hittades ej',
  optionRequirement: {
    required: 'Obligatorisk',
    min: 'Välj {min}',
    max: 'Max {max}'
  },
  optionValidation: {
    required: 'Välj en',
    min: 'Välj minst {min}',
    max: 'Välj max {max}'
  }
}
