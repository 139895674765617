import { Notify } from 'quasar'

export const notify = {
  notify: (message) => {
    Notify.create({ message })
  },
  error: (message) => {
    Notify.create({ type: 'negative', message })
  },
  warning: (message) => {
    Notify.create({ type: 'warning', message })
  },
  success: (message) => {
    Notify.create({ type: 'positive', message })
  },
  info: (message) => {
    Notify.create({ type: 'info', message })
  },
  apiError: (e) => {
    let errorMessage = null
    if (typeof e === 'string') {
      errorMessage = e
    } else {
      errorMessage = e.response?.data?.message ?? e.body?.message
    }

    if (errorMessage == null) {
      if (typeof e.response?.data === 'string') {
        errorMessage = e.response?.data
      } else if (typeof e.body === 'string') {
        errorMessage = e.body
      }
    }

    if (errorMessage == null || errorMessage === '') {
      errorMessage = e.response?.statusText ?? e.statusText
      if (errorMessage === '' || errorMessage == null) {
        errorMessage = e.message ?? e.errorMessage
      }
    }

    Notify.create({
      type: 'negative',
      message: errorMessage
    })
  }
}
