export default {
  barcodeNotFound: 'Product barcode not found',
  optionRequirement: {
    required: 'Required',
    min: 'Choose {min}',
    max: 'Max {max}'
  },
  optionValidation: {
    required: 'Select one',
    min: 'Choose at least {min}',
    max: 'Choose at most {max}'
  }
}
